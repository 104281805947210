import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RoutingAppService } from '../../../services/routing-app.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  /*------------------------------------------------------------------------------
        //* This component is used to show a loader when the route is loading
--------------------------------------------------------------------------------*/

  @Input() showLoader: boolean = false;
  private subshowLoader: Subscription

  constructor(
    private _routingApp: RoutingAppService
  ) {
    this.setshowLoaderSub()
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    if(this.subshowLoader) this.subshowLoader.unsubscribe()
  }

  //* subscribes to th eroute and shows the spinner if the route is loading
  private setshowLoaderSub = (): void => {
    this.subshowLoader = this._routingApp.getShowSpinnerSubject().subscribe((value: boolean) => {
      this.showLoader = value;
    //   console.log('this.showLoader', this.showLoader)
    },
    err => console.error,
    ()=> this.showLoader = false
    )
  }

}
