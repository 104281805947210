import * as tslib_1 from "tslib";
import { LettersService } from '../../../../services/letters.service';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { OnInit, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
export class ModalCompaniesComponent {
    constructor(letterService, clubService) {
        this.letterService = letterService;
        this.clubService = clubService;
        this.styleClass = '';
        this.isModalMode = true;
        this.selectedCompanies = [];
        this.selectedCities = [];
        this.close = new EventEmitter();
        this.companySelectedEvent = new EventEmitter();
        this.aliados = [];
        this.filteredItems = [];
        this.loadingData = true;
        this.areLoadingElementes = false;
        this.companySelectedID = [];
        this.allElementsLoades = false;
        this.scrollReachedBottom = false;
        this.limit = 15;
        this.offset = 1;
        this.toString = (object) => {
            return JSON.stringify(object);
        };
        this.infiniteScroll = (event) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const scrollEvent = event.target;
                const scrollPosition = scrollEvent.scrollTop;
                const scrollHeight = scrollEvent.scrollHeight;
                const offsetHeight = scrollEvent.offsetHeight;
                const remainingDistance = scrollHeight - scrollPosition - offsetHeight;
                if (remainingDistance <= 100 && !this.scrollReachedBottom) {
                    // Se ha alcanzado el límite de 100px y el evento no se ha ejecutado antes
                    const response = yield this.updateDataPaginated();
                    this.scrollReachedBottom = true; // Marcar como ejecutado
                }
                else if (remainingDistance > 100) {
                    this.scrollReachedBottom = false; // Reiniciar el estado si se supera el límite
                }
                // if (scrollPosition >= scrollHeight - offsetHeight ) {
                //     console.log("IDDDDDDDDD")
                // const response: any = await this.updateDataPaginated();
                // if(response==true){
                //   const newPosition = Math.round(scrollPosition - (offsetHeight * 0.2));
                //   // Mueve la página a la nueva posición
                //   scrollEvent.scrollTo({ top: newPosition, behavior: 'smooth' });
                //  }
                // }
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.initData();
            this.subjectSearch = new Subject();
            this.subjectSearch
                .pipe(debounceTime(1000))
                .subscribe((resp) => {
                console.log(resp);
                if (resp && resp.length > 0) {
                    const normalizedQuery = resp.toLowerCase();
                    this.filteredItems = this.aliados.filter((item) => {
                        return item.name.toLowerCase().includes(normalizedQuery);
                    });
                    console.log(this.filteredItems);
                    if (this.filteredItems.length < 1) {
                        this.clubService.GetCompaniesSelect(this.limit, this.offset, [], [], normalizedQuery).then(companies => {
                            console.log(companies);
                            if (companies.length > 0)
                                this.filteredItems.push(...companies);
                            else
                                this.filteredItems = [];
                        });
                    }
                }
                else {
                    this.filteredItems = [...this.aliados];
                }
            });
        });
    }
    ngOnChanges(changes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (changes['selectedCompanies'] && changes['selectedCompanies'].currentValue.length > 0) {
                console.log(this.companySelectedID);
                if (this.companySelectedID.length < 1)
                    this.companySelectedID = [...this.selectedCompanies];
            }
            else if (!changes['selectedCompanies']) {
                this.companySelectedID = [];
            }
            if (changes['selectedCities'].currentValue.length > 0) {
                console.log(this.selectedCities);
                this.filteredItems = [];
                this.loadingData = true;
                this.offset = 1;
                this.filteredItems = yield this.clubService.GetCompaniesSelect(this.limit, this.offset, this.selectedCities, []);
                this.loadingData = false;
            }
            else {
                this.filteredItems = [...this.aliados];
            }
        });
    }
    initData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const franqId of this.selectedCompanies) {
                this.companySelectedID.push(franqId);
            }
            console.log(this.companySelectedID);
            this.aliados = yield this.clubService.GetCompaniesSelect(this.limit, this.offset);
            this.loadingData = false;
            this.filteredItems = [...this.aliados];
            console.log(this.filteredItems);
        });
    }
    trackItems(index, item) {
        return item.id;
    }
    cancel() {
        this.close.emit();
    }
    confirmChanges() {
        this.companySelectedEvent.emit(this.companySelectedID);
        /*
         !En caso que se requiera guardar las franquicias en el localStorage
         this.localStorageService.set('user_franq', this.FranqSelectedId || [])
        */
    }
    searchbarInput(ev) {
        this.filterList(ev.target.value);
    }
    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */
    filterList(searchQuery) {
        /**
         * If no search query is defined,
         * return all options.
         */
        this.subjectSearch.next(searchQuery);
        // if (searchQuery === undefined) {
        //   this.filteredItems = [...this.aliados];
        // } else {
        //   /**
        //    * Otherwise, normalize the search
        //    * query and check to see which items
        //    * contain the search query as a substring.
        //    */
        //   const normalizedQuery = searchQuery.toLowerCase();
        //   this.filteredItems = this.aliados.filter((item) => {
        //     return item.name.toLowerCase().includes(normalizedQuery);
        //   });
        // }
    }
    isChecked(value) {
        return this.companySelectedID.find((item) => item === value);
    }
    checkboxChange(ev) {
        console.log(ev.target);
        const checked = ev.target.checked;
        console.log(checked);
        const value = ev.target.value;
        if (checked) {
            this.companySelectedID = [...this.companySelectedID, value];
        }
        else {
            this.companySelectedID = this.companySelectedID.filter((franquicia) => franquicia !== value);
        }
        if (!this.isModalMode)
            this.confirmChanges();
    }
    updateDataPaginated() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.allElementsLoades) {
                    this.areLoadingElementes = true;
                    //  alert("Restulados: "+this.isAllProductsLoaded+this.isloadingProducts)
                    this.offset++;
                    this.loadingData = true;
                    const getMoreData = yield this.clubService.GetCompaniesSelect(this.limit, this.offset, this.selectedCities, []);
                    yield new Promise(resolve => setTimeout(resolve, 500));
                    this.loadingData = false;
                    if (getMoreData.length > 0) {
                        this.aliados.push(...getMoreData);
                        this.filteredItems = [...this.aliados];
                        console.log(this.filteredItems);
                        console.log(this.aliados);
                        //  alert(this.allProducts.length);
                    }
                    this.allElementsLoades = (getMoreData.length < this.limit);
                    this.areLoadingElementes = false;
                }
                else
                    return Promise.resolve(false);
                return Promise.resolve(true);
            }
            catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        });
    }
    ngOnDestroy() {
        if (this.subjectSearch) {
            this.subjectSearch.unsubscribe();
        }
    }
}
