import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Pages } from '../../../interfaces/pages';
import { RoutingAppService } from '../../../services/routing-app.service';

@Component({
    selector: 'app-profile-options',
    templateUrl: './ProfileOptions.component.html',
    styleUrls: ['./ProfileOptions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileOptionsComponent implements OnInit{ 

    constructor(
       private _routing:  RoutingAppService
    ){}


    @Input() pages: Pages[] = [
        {
            show: true,
            title: "Perfil",
            url:  "club-fibex/profile",
            icon: 'person-circle-outline'

        }
    ]
    ngOnInit(): void {
        console.log("INICIALIZADO CORRECTAMENTE EL COMPONENTE DEL PERFIL");
        
    }
    moveToProfile(){
        
    }
    navigate(url: string){
        if (url === '') window.open('https://play.google.com/store/apps/details?id=com.tvappwatch.fibextelecom', '_system', 'location=yes')
        else this._routing.ChangeRuta(url)
    }    
}
