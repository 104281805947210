import * as tslib_1 from "tslib";
import { OnChanges, OnInit, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClubFibexFeedingService } from '../../../services/club-fibex-feeding.service';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { ActivatedRoute } from '@angular/router';
var FILTER_ACTIONS;
(function (FILTER_ACTIONS) {
    FILTER_ACTIONS["qCategory"] = "[FILTER] queryCategory";
    FILTER_ACTIONS["qRanking"] = "[FILTER] queryRanking";
    FILTER_ACTIONS["qPricing"] = "[FILTER] queryPricing";
    FILTER_ACTIONS["qCompanies"] = "[FILTER] queryCompanies";
    FILTER_ACTIONS["qCities"] = "[FILTER] queryCities";
    FILTER_ACTIONS["qTabDiscountType"] = "[FILTER] queryDiscountType";
})(FILTER_ACTIONS || (FILTER_ACTIONS = {}));
var COUPON_MONEY_TYPE;
(function (COUPON_MONEY_TYPE) {
    COUPON_MONEY_TYPE["DISCOUNT"] = "discount";
    COUPON_MONEY_TYPE["DOLLAR"] = "dollar";
})(COUPON_MONEY_TYPE || (COUPON_MONEY_TYPE = {}));
export class ResultsSearchComponent {
    constructor(clubService, athentication, route) {
        this.clubService = clubService;
        this.athentication = athentication;
        this.route = route;
        this.textResult = "";
        this.closeEventEmit = new EventEmitter();
        this.areLoadingProducts = true;
        this.isEnableLoadProducts = true;
        this.productsSearched = [];
        this.allProducstSearched = [];
        this.currentLimit = 4;
        this.currentOffset = 1;
        this.qCompanies = [];
        this.qCategory = [];
        this.qCities = [];
        this.qPricing = [];
        this.qTypeSearch = 0;
        this.infiniteScroll = (event) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const scrollEvent = event.target;
                const scrollPosition = scrollEvent.scrollTop;
                const scrollHeight = scrollEvent.scrollHeight;
                const offsetHeight = scrollEvent.offsetHeight;
                console.log(this.isEnableLoadProducts);
                if (this.isEnableLoadProducts) {
                    this.currentOffset++;
                    yield this.getProductsBySearching(this.textResult);
                }
                else
                    event.target.disabled = true;
                event.target.complete();
            }
            catch (error) {
                console.error(error);
            }
        });
        this.user = this.athentication.getUser();
        this.loadRouterParamsCat();
        this.subjectSearch = new Subject();
        this.subjectSearch
            .pipe(debounceTime(300))
            .subscribe(resp => {
            console.log(resp);
            try {
                if (resp) {
                    this.currentOffset = 1;
                    if (resp.length >= 1) {
                        this.areLoadingProducts = true;
                        const responseSearchLocal = this.allProducstSearched.filter(product => product.name.toLowerCase().includes(resp.toLowerCase()));
                        console.log(this.allProducstSearched);
                        console.log(responseSearchLocal);
                        if (!responseSearchLocal.length) {
                            this.productsSearched = [];
                            this.getProductsBySearching(resp);
                        }
                        else {
                            this.productsSearched = responseSearchLocal;
                            this.areLoadingProducts = false;
                        }
                        //   this.findAllysByName(resp);
                    }
                }
                else {
                    //   this.marks = this.allMarks;
                }
            }
            catch (error) {
                // console.error(error)
            }
        });
    }
    ngOnInit() {
        // this.subjectSearch
        // .pipe(
        //   debounceTime(1000), 
        // )
        // .subscribe(resp => {
        //   console.log(resp);
        //   try {
        //     if (resp) {
        //       if (resp.length >= 1) {
        //         this.areLoadingProducts = true;
        //         const responseSearchLocal = this.productsSearched.filter(product=>product.name.includes(resp))
        //         console.log(this.productsSearched);
        //         console.log(responseSearchLocal);
        //         if(!responseSearchLocal.length) this.getProductsBySearching(resp)
        //         else {
        //       this.productsSearched = responseSearchLocal;
        //           this.areLoadingProducts = false;
        //     }
        //         //   this.findAllysByName(resp);
        //       }
        //     } else {
        //     //   this.marks = this.allMarks;
        //     }
        //   } catch (error) {
        //     // console.error(error)
        //   }
        // }); 
    }
    onClick(event) {
        const modalElement = document.getElementById('ion-content-search');
        console.log(modalElement);
        if (modalElement && !modalElement.contains(event.target)) {
            console.log("EMITIDO");
            this.closeEventEmit.emit();
        }
    }
    ngOnChanges(changes) {
        if (changes["textResult"].currentValue && changes["textResult"].currentValue.length > 0) {
            const text = changes["textResult"].currentValue;
            console.log(text);
            this.subjectSearch.next(text);
        }
    }
    getProductsBySearching(name) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let companies = "";
            let categories = "";
            let franquicies = "";
            if (this.qCompanies)
                companies = this.qCompanies.join(",");
            if (this.qCategory)
                categories = this.qCategory.join(",");
            if (this.qCities)
                franquicies = this.qCities.join(",");
            const response = yield this.clubService.getProductsPaginated(franquicies, this.user.id_contrato, this.currentOffset, this.currentLimit, categories, companies, this.qPricing, this.qTypeSearch, name);
            this.productsSearched.push(...response);
            this.allProducstSearched.push(...response);
            console.log(this.productsSearched);
            this.areLoadingProducts = false;
            this.isEnableLoadProducts = response.length === this.currentLimit;
        });
    }
    loadRouterParamsCat() {
        // this.isSearchingData = true;
        var action;
        this.paramSub = this.route.queryParamMap.subscribe((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { params } = data;
            console.log('params', params);
            // this.allowInfiniteScroll = !Boolean(Object.keys( params).length)
            //Save companyID coming from the route
            this.qCompanies = [];
            this.qCities = [];
            this.qCategory = [];
            if (params.qCategory) {
                this.qCategory = params.qCategory.split('--');
                console.log('qCategory', this.qCategory);
                action = FILTER_ACTIONS.qCategory;
            }
            if (params.qPricing) {
                this.qPricing = params.qPricing.split('--');
                console.log('qPricing', this.qPricing);
                action = FILTER_ACTIONS.qPricing;
            }
            if (params.qDiscountType) {
                //   this.activeTab = params.qDiscountType;
                //   console.log('this.activeTab', this.activeTab)
                action = FILTER_ACTIONS.qTabDiscountType;
            }
            if (params.qCities) {
                this.qCities = params.qCities.split('--');
                console.log(this.qCities);
                action = FILTER_ACTIONS.qCities;
            }
            if (params.qCompanies) {
                this.qCompanies = params.qCompanies.split('--');
                console.log(this.qCompanies);
                action = FILTER_ACTIONS.qCompanies;
            }
            if (params.qtypePromoo) {
                this.qtypePromoo = params.qtypePromoo;
            }
            if (params.qTypeSearch) {
                this.qTypeSearch = params.qTypeSearch;
            }
        }));
    }
    updateProductsData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
        });
    }
    ngOnDestroy() {
        this.subjectSearch.unsubscribe();
        this.allProducstSearched = [];
        this.productsSearched = [];
    }
}
