import { CommonModule } from '@angular/common';
import { FranquiciaInterface } from '../../../../interfaces/getClubFibex.interface';
import { LettersService } from '../../../../services/letters.service';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-modal-companies',
    templateUrl: './modal-companies.component.html',
    styleUrls: ['./modal-companies.component.scss'],
})
export class ModalCompaniesComponent implements OnInit, OnChanges, OnDestroy {

   
    @Input() styleClass: string = '';
    @Input() isModalMode: boolean =  true;

    @Input() selectedCompanies: string[] = [];
    @Input() selectedCities: string[] = [];
    @Output() close: EventEmitter<void> = new EventEmitter<void>()
    @Output() companySelectedEvent: EventEmitter<string[]> = new EventEmitter<string[]>()
    public aliados: any[] = [];
    public filteredItems: any[] = [];
    public loadingData: boolean = true;
    public areLoadingElementes: boolean = false;
    public companySelectedID: string[] = [];
    public allElementsLoades:boolean = false;
    private subjectSearch: Subject<any> | undefined;
    public scrollReachedBottom = false;
    public limit = 15;
    public offset = 1;
  
    constructor(
      public letterService: LettersService,
      private clubService: ClubFibexFeedingService,
    ) {
    }
  
    async ngOnInit() {
      await this.initData();
      this.subjectSearch = new Subject<string>();

      this.subjectSearch
      .pipe(
        debounceTime(1000), 
      
      )
      .subscribe((resp:string) => {
        console.log(resp);
        if(resp && resp.length>0){
          const normalizedQuery = resp.toLowerCase();
         this.filteredItems = this.aliados.filter((item) => {
          return item.name.toLowerCase().includes(normalizedQuery);
        });
        console.log(this.filteredItems);
        
          if(this.filteredItems.length<1){
            this.clubService.GetCompaniesSelect(this.limit,this.offset,[],[],normalizedQuery).then(companies=>{
              console.log(companies);       
              if(companies.length>0) this.filteredItems.push(...companies)
              else this.filteredItems = [];
            })
           }
        }else{
          this.filteredItems = [...this.aliados];
        }
      });
    }
   async ngOnChanges(changes: SimpleChanges) {
    if(changes['selectedCompanies'] && changes['selectedCompanies'].currentValue.length>0){
      console.log(this.companySelectedID);
      if(this.companySelectedID.length < 1) this.companySelectedID = [...this.selectedCompanies];
    }
    else if(!changes['selectedCompanies']){
      this.companySelectedID = [];
    } 
     if(changes['selectedCities'].currentValue.length>0){
      console.log(this.selectedCities);
      this.filteredItems = [];
      this.loadingData = true;
      this.offset = 1;
      this.filteredItems = await this.clubService.GetCompaniesSelect(this.limit,this.offset,this.selectedCities,[]);
      this.loadingData = false;
    
    } 
    else{
      this.filteredItems = [...this.aliados];
    }
   }
    private async initData(): Promise<void> {
      for (const franqId of this.selectedCompanies) {
        this.companySelectedID.push(franqId);
      }
      console.log(this.companySelectedID);
      this.aliados = await this.clubService.GetCompaniesSelect(this.limit,this.offset);
      this.loadingData = false;
      
      this.filteredItems = [...this.aliados];
      console.log(this.filteredItems);
    }
  
    trackItems(index: number, item: any) {
      return item.id;
    }
  
    cancel() {
      this.close.emit()
    }
  
    confirmChanges() {
      this.companySelectedEvent.emit(this.companySelectedID)
      /*
       !En caso que se requiera guardar las franquicias en el localStorage 
       this.localStorageService.set('user_franq', this.FranqSelectedId || [])
      */
    }
  
    searchbarInput(ev) {
      this.filterList(ev.target.value);
    }
  
    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */
    filterList(searchQuery: string | undefined) {
      /**
       * If no search query is defined,
       * return all options.
       */
      this.subjectSearch.next(searchQuery);
      // if (searchQuery === undefined) {
      //   this.filteredItems = [...this.aliados];
      // } else {
      //   /**
      //    * Otherwise, normalize the search
      //    * query and check to see which items
      //    * contain the search query as a substring.
      //    */
      //   const normalizedQuery = searchQuery.toLowerCase();
      //   this.filteredItems = this.aliados.filter((item) => {
      //     return item.name.toLowerCase().includes(normalizedQuery);
      //   });
      // }
    }
  
    isChecked(value: string) {
      return this.companySelectedID.find((item) => item === value);
    }
  
    checkboxChange(ev) {
      
   console.log(ev.target);
      const checked: number = ev.target.checked
      console.log(checked);
      const value = ev.target.value;
      if (checked) {
        this.companySelectedID = [...this.companySelectedID, value];
      } else {
        this.companySelectedID = this.companySelectedID.filter((franquicia) => franquicia !== value);
      }
      if(!this.isModalMode) this.confirmChanges();
    }
  
    public toString = (object: unknown): string => {
      return JSON.stringify(object)
    }
    public infiniteScroll = async (event:Event) => {
        try {
        const scrollEvent = event.target as HTMLElement;
        const scrollPosition = scrollEvent.scrollTop;
        const scrollHeight = scrollEvent.scrollHeight; 
        const offsetHeight = scrollEvent.offsetHeight;
        const remainingDistance = scrollHeight-scrollPosition-offsetHeight;
        if (remainingDistance <= 100 && !this.scrollReachedBottom) {
          // Se ha alcanzado el límite de 100px y el evento no se ha ejecutado antes
          const response: any = await this.updateDataPaginated();
          this.scrollReachedBottom = true; // Marcar como ejecutado
        } else if (remainingDistance > 100) {
          this.scrollReachedBottom = false; // Reiniciar el estado si se supera el límite
        }
        // if (scrollPosition >= scrollHeight - offsetHeight ) {
        //     console.log("IDDDDDDDDD")
        // const response: any = await this.updateDataPaginated();
        // if(response==true){
        //   const newPosition = Math.round(scrollPosition - (offsetHeight * 0.2));
        //   // Mueve la página a la nueva posición
        //   scrollEvent.scrollTo({ top: newPosition, behavior: 'smooth' });
        //  }
        // }
       
        } catch (error) {
          console.error(error);
        }
        
    
      }
      public async updateDataPaginated(){
        try {
          if(!this.allElementsLoades){
            this.areLoadingElementes= true;
          //  alert("Restulados: "+this.isAllProductsLoaded+this.isloadingProducts)
            this.offset++;
            this.loadingData=true;
            const getMoreData: any[] = await this.clubService.GetCompaniesSelect(this.limit,this.offset,this.selectedCities,[]);
            await new Promise(resolve => setTimeout(resolve, 500));
            this.loadingData=false;
            if(getMoreData.length>0){
             this.aliados.push(...getMoreData);
             this.filteredItems = [...this.aliados];
             console.log(this.filteredItems);
             
             console.log(this.aliados);
             
             //  alert(this.allProducts.length);
            
            }
            this.allElementsLoades = (getMoreData.length<this.limit);
            this.areLoadingElementes = false;
          }
          else return Promise.resolve(false);
        return  Promise.resolve(true);
        } catch (error) {
          console.log(error);
          return Promise.reject(error);
        }

      }
    ngOnDestroy(): void {
      if(this.subjectSearch){
        this.subjectSearch.unsubscribe();
      }
    
    }  
}