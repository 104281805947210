import { Injectable, EventEmitter } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {
  currentSubscriber$ = new EventEmitter<boolean>(false)

  constructor() { }

  insertTo(values, table){
    const headers = {
        none:'for now'
    }

    // axios.post("",values,{ headers })
  }
}
