import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { MenuController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { Router } from '@angular/router';
import { RoutingAppService } from '../../../services/routing-app.service';
import { FilterComponent } from '../filter/filter.page';
import { ProfileOptionsComponent } from '../ProfileOptions/ProfileOptions.component';
import { PushMessageListComponent } from '../push-message-list/push-message-list.component';
import { PushMessagesService } from '../../../services/push-messages.service';
export class ClubHeaderComponent {
    constructor(modalCtrl, router, athentication, _routing, _platform, popoverController, menssageSvc, menu) {
        this.modalCtrl = modalCtrl;
        this.router = router;
        this.athentication = athentication;
        this._routing = _routing;
        this._platform = _platform;
        this.popoverController = popoverController;
        this.menssageSvc = menssageSvc;
        this.menu = menu;
        this.showSearchBar = false;
        this.showFilterBtn = false;
        this.showLightMode = false;
        this.canShowResultsSearcher = false;
        this.navigateToHome = new EventEmitter();
        this.goToSearchEmit = new EventEmitter();
        this.handleSearchEmit = new EventEmitter();
        this.NewMsg = false;
        this.isIos = false;
        this.isLoadingModal = false;
        this.text = "";
        this.isVisibleModalSearch = false;
        this.isDesktop = true;
        this.user = this.athentication.getUser();
        this.isIos = this._platform.is('ios');
    }
    ngOnInit() {
        this.isDesktop = this._platform.is('desktop');
        console.log(this.isDesktop);
    }
    // async callClient(): Promise<void> {
    //   const modal = await this.modalCtrl.create({
    //     component: CallMePage,
    //     componentProps: { DataClient: {cedula:this.user.identidad}, fromClubFibex: true },
    //     mode: 'ios'
    //   });
    //   await modal.present();
    // }
    goToClubIframe() {
        this.router.navigate(['club-fibex', 'club-iframe']);
    }
    navegateHome() {
        this.navigateToHome.emit();
    }
    openFilterModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoadingModal = true;
            const modal = yield this.modalCtrl.create({
                component: FilterComponent,
                cssClass: 'full-screen-modal ',
                mode: 'ios'
            });
            this.isLoadingModal = false;
            modal.present();
        });
    }
    goToSearch() {
        this.goToSearchEmit.emit();
    }
    handleSearch(event) {
        this.text = event.target.value;
        if (this.canShowResultsSearcher) {
            this.isVisibleModalSearch = this.text && this.text.length > 0;
        }
        console.log(event);
        this.handleSearchEmit.emit(event);
    }
    goToChat() {
        this.NewMsg = false;
        this._routing.ChangeRuta("chat");
    }
    setValueModalSearchVisinel(value) {
        this.isVisibleModalSearch = value;
    }
    openModalOptions(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popover = yield this.popoverController.create({
                component: ProfileOptionsComponent,
                cssClass: 'popover_small',
                event: event,
                translucent: true,
                mode: 'ios',
            });
            yield popover.present();
        });
    }
    toggleMenu() {
        this.menu.toggle();
        this.menu.toggle();
        this.menu.open();
        this.menu.enable(true);
    }
    presentPushMessage(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const OptionListProps = ['Solicitar', 'Soporte', 'Reclamo', 'Calificar'];
            const popover = yield this.popoverController.create({
                component: PushMessageListComponent,
                cssClass: 'popover_small',
                event: ev,
                translucent: true,
                mode: 'ios',
                componentProps: { 'optionList': OptionListProps }
            });
            yield popover.present();
        });
    }
}
