import { Component, OnInit, OnDestroy } from '@angular/core';
import { PushMessagesService } from '../../../services/push-messages.service';
import { PushMessageInterface } from '../../../interfaces/push-message';
import { Router } from '@angular/router';
import { Platform, PopoverController } from '@ionic/angular';


@Component({
  selector: 'app-push-message-list',
  templateUrl: './push-message-list.component.html',
  styleUrls: ['./push-message-list.component.scss'],
})
export class PushMessageListComponent implements OnInit, OnDestroy {

  public isPushUrlView: boolean = false;

  fechaHoraActual = new Date();
  fechaHoraFormateada = this.fechaHoraActual.toISOString();
  readRecent: any = [];

  constructor(
    public pushMessageService: PushMessagesService,
    private router: Router,
    public popoverController: PopoverController,
    public _platform: Platform
  ) {
    if (this.router.url.includes('push-message')) this.isPushUrlView = true;
  }

  ngOnInit() {
    // Verificamos que solo exista una notificacion para expandir el bloque
    if (this.pushMessageService.notificationNotRead.length == 1) {
      setTimeout(() => {
        const notification = this.pushMessageService.notificationNotRead[0];
        const content = document.getElementById(`item_id_${notification.id}`);
        if (content.className.includes('item_closed')) {
          content.classList.remove('item_closed')
          content.classList.add('item_active')
        }
      }, 100)
    }
  }

  handleActiveMessage(message: PushMessageInterface, index: number) {
    const content = document.getElementById(`item_id_${message.id}`);

    if (content.className.includes('item_closed')) {
      content.classList.remove('item_closed')
      content.classList.add('item_active')
    }
    else {
      content.classList.remove('item_active')
      content.classList.add('item_closed')
    }

    if (!message.read_at) {
      this.pushMessageService.markMessageRead(message);
    }
  }

  public goToPushMessagePage(): void {
    this.popoverController.dismiss()
    this.router.navigateByUrl('/push-message')
  }

  markAllAsRead() {
    this.pushMessageService.notificationNotRead.forEach((message: any, index: number) => {
      this.pushMessageService.markAllAsRead(message, index);
    });
  }

  redirectByUrl(url: string) {
    this.popoverController.dismiss()
    this.router.navigateByUrl(url);
  }

  showMoreUnread() {
    try {
      console.log("Unread");
      const userData = JSON.parse(localStorage.getItem('DataToRegister') || "");
      const user = (userData && userData.DataUserSae) || null;
      
      if(user) {
        this.pushMessageService.loadMoreMessagesUnreaded(user.nro_contrato);
      }
    }
    catch(err) {
      console.error(err);
    }
  }

  ngOnDestroy() {
    this.pushMessageService.notificationNotRead = this.pushMessageService.notificationNotRead.filter(notification => notification.read_at === null);
  }
}
