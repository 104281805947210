import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal1',
  templateUrl: './modal1.component.html',
  styleUrls: ['./modal1.component.scss'],
})
export class Modal1Component implements OnInit {

  @Output() successBtnEmit: EventEmitter<void | string> = new EventEmitter<void | string>(null);
  @Output() cancelBtnEmit = new EventEmitter<void>(null);
  @Input() cancelTextBtn: string = '';
  @Input() successTextBtn: string = '';
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() modalImage: string = '';
  @Input() formPlaceholder: string = '';
  @Input() formType: 'number' | 'text';

  public formValue: string = '';
  public lastFormValue: string = '';

  constructor() { }

  ngOnInit() {}

  successEvent() {
    const valueToEmit: null | string = this.formValue || null
    console.log('valueToEmit:', valueToEmit)
    this.successBtnEmit.emit(valueToEmit)
    this.formValue = undefined
  }

  onCancelEvent = (): void => {
    console.log('formValue', this.formValue)
    this.cancelBtnEmit.emit()
  }

  public onInputValue = (inputElem: HTMLInputElement): void => {
    const regEx = /^[0-9]*$/;
    if (regEx.test(this.formValue)) {
      let formVal = this.formValue && this.formValue.substr(0,6);
      if (formVal) {
        this.formValue = formVal
        this.lastFormValue = this.formValue;
        inputElem.value = this.formValue
      }
    }
    else {
      this.formValue = this.lastFormValue;
      inputElem.value = this.lastFormValue;
    }
  }

}
