import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import { TranslateProvider } from './providers';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
// import { Geolocation } from '@ionic-native/geolocation/ngx';
// import { SuscipcionRemotoService } from './pages/ctrlremote/suscipcion-remoto.service';
import { SeguridadDatos } from './services/bscript.service';
import { PipesModule } from './pipes/pipes.module';
// import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
// import { PropagandaPage } from './pages/modal/propaganda/propaganda.page';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/http-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { LoaderComponent } from './shared/components/loader/loader.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule, 
    BrowserAnimationsModule,
    IonicModule.forRoot(environment.config),
    AppRoutingModule,
    HttpClientModule,
    PipesModule,
    NgIdleKeepaliveModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__ionbooking2',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB3zfryBJhGSyX955qUwNn2Rfnr5lpeVlo'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Network,
    // Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UniqueDeviceID,
    // TranslateProvider,
    // BarcodeScanner,
    // SuscipcionRemotoService,
    SeguridadDatos,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    SharedModule
  ],
  /*entryComponents: [
    PropagandaPage
  ],*/
  bootstrap: [AppComponent]
})
export class AppModule { }
